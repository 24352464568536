import React from 'react';
import '../shared/styles.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function NotifyButton() {
  const navigate = useNavigate(); // Hook for navigation
  const cdrid = localStorage.getItem('cdrid');

  const submitCdrid = async () => {
    // Redirect to /Notify immediately when the button is clicked
    navigate('/Notify');

    try {
      await fetch('https://api.retool.com/v1/workflows/99c833b1-1700-4b93-9ba0-ac041d34cf94/startTrigger?workflowApiKey=retool_wk_ad69c5fd01be4864b62d11d35c379f57', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cdrid }), // Use the cdrid from localStorage
      });

      // Since we're navigating immediately, there's no need to handle the response here.
      // If you still want to log the response or handle errors, you can do so without affecting the UI.
    } catch (error) {
      console.error('Error:', error);
      // Even though there's an error, the user has already been navigated to the new page.
    }
  };

  return (
    <button className="goButton" onClick={submitCdrid} type="button">Notify Me</button>
  );
}

export default NotifyButton;
