import React, { useState, useEffect } from 'react';
import './shared/shared.css';
import RetrieveTransactions from './RetrieveTransactions';
import Logo from './images/logo.png';
import NotifyButton from './buttons/notifyButton';

function Consented() {
  const [showNotifyButton, setShowNotifyButton] = useState(false); // State to control the display of the NotifyButton

  useEffect(() => {
    // Set a timer to change showNotifyButton to true after 10 seconds
    const timer = setTimeout(() => {
      setShowNotifyButton(true);
    }, 3000); 

    // Clean up the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, []); // Empty dependency array means this effect runs only once after the initial render

  return (
    <div className="container">
      <img src={Logo} alt="logo" className="hero-image" />
      <div className="header">
     
        <h1 className="title">Searching...</h1>
        
        <RetrieveTransactions /> 
        {/* Conditionally render the NotifyButton based on showNotifyButton state */}
        </div>
        <p className="subtext">
          Why wait around? Opt in to get notified instantly once we've unlocked your potential rewards.
        </p>
       
      
      {showNotifyButton && <NotifyButton />}
    </div>
  );
}

export default Consented;
