import React from 'react';
import './shared/shared.css';
import Logo from './images/logo.png'



function ShowMissed() {

const uniqueTotalMerchants = parseFloat(localStorage.getItem('uniqueMerchants')) + parseFloat(localStorage.getItem('altUniqueMerchants'));
const totalCashBack = (parseFloat(localStorage.getItem('totalRewardsValue')) + parseFloat(localStorage.getItem('altTotalRewardsValue'))).toFixed(2);
const formattedTotalCashBack = parseFloat(totalCashBack).toLocaleString();



  return (
    <div className="container">
   <img src={Logo} alt="logo" className="hero-image" />
      <div className="header">
     
     
      <h1 className="title">FOUND!</h1>

          <p>
        At first glance we have found purchases from <b>{uniqueTotalMerchants}</b> stores that may have qualified for <b>${formattedTotalCashBack}</b>  cashback rewards!
          </p><p>By registering in our rewards program and adding your cards, we can make sure you don't miss out on cashback rewards in the future. </p>
        
        </div>

        <div className="footer">
          <button className="goButton" >Join Rewards</button>
            </div></div>

         

      
     
  );
}

export default ShowMissed;
