import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage.js';
import Resume from './components/Resume.js';
import './App.css'
import LaunchConsent from './components/LaunchConsent.js';
import Consented from './components/Consented.js';
import ShowMissed from './components/ShowMissed.js';
import ConsentedWithJobId from './components/ConsentedWithJobId.js';
import Notify from './components/Notify.js'


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/Resume" element={<Resume />} />
        <Route path="/LaunchConsent" element={<LaunchConsent />} />
        <Route path="/Consented" element={<Consented />} />
        <Route path="/ShowMissed" element={<ShowMissed />} />
        <Route from="/Consented" element={ConsentedWithJobId} />
        <Route path="/Notify" element={<Notify />} />

  
        {/* <Route path="/" element={<LandingPage />} /> */}
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
