import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function ConsentedWithJobId() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get('jobId');

    if (jobId) {
      // Handle redirection logic here based on jobId
      // For example, you can navigate to a specific route based on jobId
      if (jobId === 'jobId') {
        navigate('/Consented');
      } else {
        navigate('/Consented');
      }
    } else {
      // Handle the case when jobId is not present in the query parameters
      navigate('/');
    }
  }, [location, navigate]);

  return (
    <div>
      {/* You can render content here if needed */}
    </div>
  );
}

export default ConsentedWithJobId;
