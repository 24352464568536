import React from 'react';
import './shared/shared.css';
import Logo from './images/logo.png'




function Notify() {

  return (
    <div className="container">
   <img src={Logo} alt="logo" className="hero-image" />

      <div className="header">
     
     
      {/* <h1 className="title">NOTIFY ME</h1> */}

      <p className="subtext">
        We will send you an email once we have finished!
        </p>
        </div>

        <div className="footer">
          <button className="goButton" >Join Rewards</button>
            </div></div>

         

      
     
  );
}

export default Notify;
