import React, { useState } from 'react';
import '../shared/styles.css';


function RegisterEmail() {
  const [email, setEmail] = useState('');


  const submitEmail = async (email) => {
    try {
      const response = await fetch('https://api.dev.cdr.sipora.io/launchro', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'tenantId': 'BEEM-DEV',
          'apiKey': 'drgchocODxMjwEwpyB76mG0QYPpUpVtwnbyEnuyUJRggRUshbeqXNkVopAEXlvxU',
        },
        body: JSON.stringify({ email }),
      });

      const responseData = await response.json();

      if (response.ok) {
        console.log("API call successful:", responseData);
        console.log("Redirecting to:", responseData.roLink);

        // Use navigate from react-router-dom for navigation
        window.location.href = responseData.roLink;
      } else {
        // Handle non-successful responses
        console.error('Error submitting email:', responseData.message);
        alert(responseData.message || 'Error submitting email');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the email.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await submitEmail(email);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="email">Email Address:</label>
      <input
        type="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <button className="goButton" type="submit">Register</button>
    </form>
  );
}

export default RegisterEmail;
