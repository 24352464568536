import React from 'react';
import './shared/styles.css';
import StartButton from './buttons/start';
import ShowMissedButton from './buttons/ShowMissedButton'; // Assuming this component exists
import Logo from './images/logo.png'

function Resume() {
  const queryParams = new URLSearchParams(window.location.search);
  const cdrid = queryParams.get('cdrid');
  const existingValue = queryParams.get('existing') || 'false'; 

  // Optionally set in localStorage if needed elsewhere
  localStorage.setItem('cdrid', cdrid);
  localStorage.setItem('existing', existingValue);

  // Determine which button to display based on the existingValue
  const isExistingSuccessful = existingValue === 'successful';
  
  return (
    <div className="container">
   <img src={Logo} alt="logo" className="hero-image" />

      <div className="header">
        <h1 className="title">Unlock Missed Rewards</h1>
        <p className="subtext">
          The Rewards Optimiser Connects Securely To Your Bank, Analyses Your Transactions,
          And Shows You Exactly What You've Been Missing.
        </p>
        <p>Ready To See What You're Leaving On The Table?</p>
      </div>
      {isExistingSuccessful ? (
          <ShowMissedButton cdrid={cdrid} />
        ) : (
          <StartButton cdrid={cdrid} />
        )}
      </div>
  
  );
}

export default Resume;