import React, { useState, } from 'react';
import { useNavigate  } from 'react-router-dom'; // Import useHistory
import './buttons.css';


function StartButton() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const cdrid = localStorage.getItem('cdrid');
  const handleButtonClick = async () => {
    setIsLoading(true);


    try {
      const response = await fetch('https://api.dev.cdr.sipora.io/user/consent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'tenantId': "BEEM-DEV",
          'apiKey': "drgchocODxMjwEwpyB76mG0QYPpUpVtwnbyEnuyUJRggRUshbeqXNkVopAEXlvxU" //PASS FROM APP
        },
        body: JSON.stringify({
          cdrid: cdrid,
        }),
      });

      if (!response.ok) {
        throw new Error(`API call failed: ${response.status}`);
      }

      const responseData = await response.json();
      const consentLink = responseData.consentLink;

      if (consentLink) {
        localStorage.setItem('consentLink', consentLink);
      }
     

      navigate('/LaunchConsent');

      setIsLoading(false);
    } catch (error) {
      console.error('Registration failed:', error);
      alert(error.message);
      setIsLoading(false);
    }
  };



  return (

      <button className="goButton" onClick={handleButtonClick} disabled={isLoading}>
        {isLoading ? <div className="loadingSpinner"></div> : 'Start'}
      </button>

  );
}

export default StartButton;